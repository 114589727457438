import keys from './keys.js';
import utils, { normalizeBase64ToBuf, normalizeUnicodeToBuf } from '../utils.js';
import { DEFAULT_CHAR_SIZE, DEFAULT_HASH_ALG, RSA_EXCHANGE_ALG, RSA_WRITE_ALG, SALT_LENGTH } from '../constants.js';
import { KeyUse } from '../types.js';
import { webcrypto } from 'one-webcrypto';
export async function sign(msg, privateKey, charSize = DEFAULT_CHAR_SIZE) {
    return webcrypto.subtle.sign({ name: RSA_WRITE_ALG, saltLength: SALT_LENGTH }, privateKey, normalizeUnicodeToBuf(msg, charSize));
}
export async function verify(msg, sig, publicKey, charSize = DEFAULT_CHAR_SIZE, hashAlg = DEFAULT_HASH_ALG) {
    return webcrypto.subtle.verify({ name: RSA_WRITE_ALG, saltLength: SALT_LENGTH }, typeof publicKey === "string"
        ? await keys.importPublicKey(publicKey, hashAlg, KeyUse.Write)
        : publicKey, normalizeBase64ToBuf(sig), normalizeUnicodeToBuf(msg, charSize));
}
export async function encrypt(msg, publicKey, charSize = DEFAULT_CHAR_SIZE, hashAlg = DEFAULT_HASH_ALG) {
    return webcrypto.subtle.encrypt({ name: RSA_EXCHANGE_ALG }, typeof publicKey === "string"
        ? await keys.importPublicKey(publicKey, hashAlg, KeyUse.Exchange)
        : publicKey, normalizeUnicodeToBuf(msg, charSize));
}
export async function decrypt(msg, privateKey) {
    const normalized = normalizeBase64ToBuf(msg);
    return webcrypto.subtle.decrypt({ name: RSA_EXCHANGE_ALG }, privateKey, normalized);
}
export async function getPublicKey(keypair) {
    const spki = await webcrypto.subtle.exportKey('spki', keypair.publicKey);
    return utils.arrBufToBase64(spki);
}
export default {
    sign,
    verify,
    encrypt,
    decrypt,
    getPublicKey,
};
