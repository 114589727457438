export class BaseFile {
    constructor(content) {
        this.content = content;
    }
    async put() {
        const { cid } = await this.putDetailed();
        return cid;
    }
    async updateContent(content) {
        this.content = content;
        return this;
    }
}
export default BaseFile;
