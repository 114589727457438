import { IPFSClient } from "ipfs-message-port-client";
import { setup } from "../setup/internal.js";
let ipfs = null;
export const set = (userIpfs) => {
    ipfs = userIpfs;
};
export const get = async () => {
    if (!ipfs) {
        const port = await iframe();
        ipfs = IPFSClient.from(port);
    }
    return ipfs;
};
export function iframe() {
    return new Promise((resolve, reject) => {
        const iframe = document.createElement("iframe");
        iframe.id = "webnative-ipfs";
        iframe.style.width = "0";
        iframe.style.height = "0";
        iframe.style.border = "none";
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        iframe.onload = () => {
            const channel = new MessageChannel();
            channel.port1.onmessage = ({ ports }) => resolve(ports[0]);
            iframe.contentWindow
                ? iframe.contentWindow.postMessage("connect", "*", [channel.port2])
                : reject(new Error("Don't have access to iframe.contentWindow"));
        };
        iframe.src = `${setup.endpoints.lobby}/ipfs/v2.html`;
    });
}
