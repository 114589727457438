import FileSystem from "./fs/index.js";
import * as cidLog from "./common/cid-log.js";
import * as debug from "./common/debug.js";
import * as dataRoot from "./data-root.js";
import * as ucan from "./ucan/internal.js";
import * as protocol from "./fs/protocol/index.js";
import * as versions from "./fs/versions.js";
import * as setup from "./setup.js";
import { Branch } from "./path.js";
import { authenticatedUsername } from "./common/index.js";
/**
 * Load a user's file system.
 *
 * @param permissions The permissions from initialise.
 *                    Pass `null` if working without permissions
 * @param username Optional, username of the user to load the file system from.
 *                 Will try to load the file system of the authenticated user
 *                 by default. Throws an error if there's no authenticated user.
 * @param rootKey Optional, AES key to be the root key of a new filesystem.
 *                Will be used if a filesystem hasn't been created yet.
 */
export async function loadFileSystem(permissions, username, rootKey) {
    let cid, fs;
    // Look for username
    username = username || (await authenticatedUsername() || undefined);
    if (!username)
        throw new Error("User hasn't authenticated yet");
    // Ensure internal UCAN dictionary
    await ucan.store([]);
    // Determine the correct CID of the file system to load
    const dataCid = navigator.onLine ? await dataRoot.lookup(username) : null;
    const [logIdx, logLength] = dataCid ? await cidLog.index(dataCid) : [-1, 0];
    if (!navigator.onLine) {
        // Offline, use local CID
        cid = await cidLog.newest();
    }
    else if (!dataCid) {
        // No DNS CID yet
        cid = await cidLog.newest();
        if (cid)
            debug.log("📓 No DNSLink, using local CID:", cid);
        else
            debug.log("📓 Creating a new file system");
    }
    else if (logIdx === 0) {
        // DNS is up to date
        cid = dataCid;
        debug.log("📓 DNSLink is up to date:", cid);
    }
    else if (logIdx > 0) {
        // DNS is outdated
        cid = await cidLog.newest();
        const idxLog = logIdx === 1 ? "1 newer local entry" : logIdx + " newer local entries";
        debug.log("📓 DNSLink is outdated (" + idxLog + "), using local CID:", cid);
    }
    else {
        // DNS is newer
        cid = dataCid;
        await cidLog.add(cid);
        debug.log("📓 DNSLink is newer:", cid);
        // TODO: We could test the filesystem version at this DNSLink at this point to figure out whether to continue locally.
        // However, that needs a plan for reconciling local changes back into the DNSLink, once migrated. And a plan for migrating changes
        // that are only stored locally.
    }
    // If a file system exists, load it and return it
    const p = permissions || undefined;
    if (cid != null) {
        await checkVersion(cid);
        fs = await FileSystem.fromCID(cid, { permissions: p });
        if (fs != null)
            return fs;
    }
    // Otherwise make a new one
    if (!rootKey)
        throw new Error("Can't make new filesystem without a root AES key");
    fs = await FileSystem.empty({ permissions: p, rootKey });
    await addSampleData(fs);
    // Fin
    return fs;
}
export async function checkVersion(filesystemCID) {
    const links = await protocol.basic.getLinks(filesystemCID);
    // if there's no version link, we assume it's from a 1.0.0-comatible version (from before ~ November 2020)
    const versionStr = links[Branch.Version] == null ? "1.0.0" : new TextDecoder().decode(await protocol.basic.getFile(links[Branch.Version].cid));
    if (versionStr !== versions.toString(versions.latest)) {
        const versionParsed = versions.fromString(versionStr);
        const userMessages = setup.userMessages({});
        if (versionParsed == null || versions.isSmallerThan(versions.latest, versionParsed)) {
            await userMessages.versionMismatch.newer(versionStr);
            throw new Error(`Incompatible filesystem version. Version: ${versionStr} Supported: ${versions.toString(versions.latest)} Please upgrade this app's webnative version.`);
        }
        await userMessages.versionMismatch.older(versionStr);
        throw new Error(`Incompatible filesystem version. Version: ${versionStr} Supported: (${versions.toString(versions.latest)} The user should migrate their filesystem.`);
    }
}
// ㊙️
async function addSampleData(fs) {
    await fs.mkdir({ directory: [Branch.Private, "Apps"] });
    await fs.mkdir({ directory: [Branch.Private, "Audio"] });
    await fs.mkdir({ directory: [Branch.Private, "Documents"] });
    await fs.mkdir({ directory: [Branch.Private, "Photos"] });
    await fs.mkdir({ directory: [Branch.Private, "Video"] });
    await fs.publish();
}
