import aes from '../aes/index.js';
import idb from '../idb.js';
import utils from '../utils.js';
import config from '../config.js';
import { checkIsKeyPair } from '../errors.js';
export default class KeyStoreBase {
    constructor(cfg, store) {
        this.cfg = cfg;
        this.store = store;
    }
    async writeKey() {
        const maybeKey = await idb.getKeypair(this.cfg.writeKeyName, this.store);
        return checkIsKeyPair(maybeKey);
    }
    async exchangeKey() {
        const maybeKey = await idb.getKeypair(this.cfg.exchangeKeyName, this.store);
        return checkIsKeyPair(maybeKey);
    }
    async getSymmKey(keyName, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const maybeKey = await idb.getKey(keyName, this.store);
        if (maybeKey !== null) {
            return maybeKey;
        }
        const key = await aes.makeKey(config.symmKeyOpts(mergedCfg));
        await idb.put(keyName, key, this.store);
        return key;
    }
    async keyExists(keyName) {
        const key = await idb.getKey(keyName, this.store);
        return key !== null;
    }
    async deleteKey(keyName) {
        return idb.rm(keyName, this.store);
    }
    async destroy() {
        return idb.dropStore(this.store);
    }
    async importSymmKey(keyStr, keyName, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const key = await aes.importKey(keyStr, config.symmKeyOpts(mergedCfg));
        await idb.put(keyName, key, this.store);
    }
    async exportSymmKey(keyName, cfg) {
        const key = await this.getSymmKey(keyName, cfg);
        return aes.exportKey(key);
    }
    async encryptWithSymmKey(msg, keyName, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const key = await this.getSymmKey(keyName, cfg);
        const cipherText = await aes.encryptBytes(utils.strToArrBuf(msg, mergedCfg.charSize), key, config.symmKeyOpts(mergedCfg));
        return utils.arrBufToBase64(cipherText);
    }
    async decryptWithSymmKey(cipherText, keyName, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const key = await this.getSymmKey(keyName, cfg);
        const msgBytes = await aes.decryptBytes(utils.base64ToArrBuf(cipherText), key, config.symmKeyOpts(mergedCfg));
        return utils.arrBufToStr(msgBytes, mergedCfg.charSize);
    }
}
