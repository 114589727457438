import { isNum, isObject, isString } from "../../../../common/index.js";
import * as check from "../../../types/check.js";
export const isDecryptedNode = (obj) => {
    return isPrivateTreeInfo(obj) || isPrivateFileInfo(obj);
};
export const isPrivateFileInfo = (obj) => {
    return isObject(obj)
        && check.isMetadata(obj.metadata)
        && obj.metadata.isFile
        && isString(obj.key)
        && check.isCID(obj.content);
};
export const isPrivateTreeInfo = (obj) => {
    return isObject(obj)
        && check.isMetadata(obj.metadata)
        && obj.metadata.isFile === false
        && isNum(obj.revision)
        && isPrivateLinks(obj.links)
        && isPrivateSkeleton(obj.skeleton);
};
export const isPrivateLink = (obj) => {
    return check.isBaseLink(obj)
        && isString(obj.key)
        && isString(obj.pointer);
};
export const isPrivateLinks = (obj) => {
    return isObject(obj)
        && Object.values(obj).every(isPrivateLink);
};
export const isPrivateSkeleton = (obj) => {
    return isObject(obj)
        && Object.values(obj).every(isPrivateSkeletonInfo);
};
export const isPrivateSkeletonInfo = (obj) => {
    return isObject(obj)
        && check.isCID(obj.cid)
        && isString(obj.key)
        && isPrivateSkeleton(obj.subSkeleton);
};
