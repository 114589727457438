import * as uint8arrays from "uint8arrays";
import { webcrypto } from "one-webcrypto";
import { importKey, encryptBytes, decryptBytes, makeKey, exportKey } from "keystore-idb/lib/aes/index.js";
import { SymmKeyLength } from "keystore-idb/lib/types.js";
import { impl } from "../setup/dependencies.js";
export const sha256Str = async (str) => {
    const hash = await webcrypto.subtle.digest("sha-256", uint8arrays.fromString(str));
    return uint8arrays.toString(new Uint8Array(hash), "hex");
};
export const hash = {
    sha256: async (bytes) => new Uint8Array(await webcrypto.subtle.digest("sha-256", bytes)),
    sha256Str: sha256Str
};
export const aes = {
    encrypt: async (data, keyStr) => {
        const key = await importKey(keyStr, { length: SymmKeyLength.B256 });
        const encrypted = await encryptBytes(data, key);
        return new Uint8Array(encrypted);
    },
    decrypt: async (encrypted, keyStr) => {
        const key = await importKey(keyStr, { length: SymmKeyLength.B256 });
        const decryptedBuf = await decryptBytes(encrypted, key);
        return new Uint8Array(decryptedBuf);
    },
    genKeyStr: async () => {
        const key = await makeKey({ length: SymmKeyLength.B256 });
        return exportKey(key);
    },
    decryptGCM: async (encrypted, keyStr, ivStr) => {
        const iv = uint8arrays.fromString(ivStr, "base64pad");
        const sessionKey = await crypto.subtle.importKey("raw", uint8arrays.fromString(keyStr, "base64pad"), "AES-GCM", false, ["encrypt", "decrypt"]);
        // Decrypt secrets
        const decrypted = await crypto.subtle.decrypt({
            name: "AES-GCM",
            iv: iv
        }, sessionKey, uint8arrays.fromString(encrypted, "base64pad"));
        return uint8arrays.toString(new Uint8Array(decrypted));
    }
};
export const rsa = {
    verify: (message, signature, publicKey) => impl.rsa.verify(message, signature, publicKey)
};
export const ed25519 = {
    verify: (message, signature, publicKey) => impl.ed25519.verify(message, signature, publicKey)
};
export const keystore = {
    publicExchangeKey: () => impl.keystore.publicExchangeKey(),
    publicWriteKey: () => impl.keystore.publicWriteKey(),
    decrypt: (encrypted) => impl.keystore.decrypt(encrypted),
    sign: (message, charSize) => impl.keystore.sign(message, charSize),
    importSymmKey: (key, name) => impl.keystore.importSymmKey(key, name),
    exportSymmKey: (name) => impl.keystore.exportSymmKey(name),
    keyExists: (keyName) => impl.keystore.keyExists(keyName),
    getAlg: () => impl.keystore.getAlg(),
    clear: () => impl.keystore.clear()
};
