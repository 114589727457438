/**
 * @internal
 */
export const setup = {
    debug: false,
    endpoints: {
        api: "https://runfission.com",
        apiVersion: "v2",
        apiUrl: null,
        lobby: "https://auth.fission.codes",
        user: "fission.name"
    },
    getApiEndpoint: () => {
        if (setup.endpoints.apiUrl) {
            return setup.endpoints.apiUrl;
        }
        return `${setup.endpoints.api}/${setup.endpoints.apiVersion}/api`;
    },
    userMessages: {
        versionMismatch: {
            newer: async () => alertIfPossible(`Sorry, we can't sync your filesystem with this app. This app only understands older versions of filesystems. Please try to hard refresh this site or let this app's developer know. Feel free to contact Fission support: support@fission.codes`),
            older: async () => alertIfPossible(`Sorry, we can't sync your filesystem with this app. Your filesystem version is out-dated and it needs to be migrated. Use the migration app or talk to Fission support: support@fission.codes`),
        }
    },
    shouldPin: false,
};
function alertIfPossible(str) {
    if (globalThis.alert != null)
        globalThis.alert(str);
}
