import dagPb from "ipld-dag-pb";
import { mtimeFromMs } from "./metadata.js";
export const toDAGLink = (link) => {
    const { name, cid, size } = link;
    return new dagPb.DAGLink(name, size, cid);
};
export const fromFSFile = (fsObj) => {
    const { name = "", cid, size, mtime, type } = fsObj;
    return {
        name,
        cid: cid.toString(),
        size,
        mtime,
        isFile: type !== "dir"
    };
};
export const fromDAGLink = (link) => {
    const name = link.Name;
    const cid = link.Hash.toString();
    const size = link.Tsize;
    return { name, cid, size };
};
export const make = (name, cid, isFile, size) => {
    return {
        name,
        cid,
        size,
        isFile,
        mtime: mtimeFromMs(Date.now())
    };
};
export const arrToMap = (arr) => {
    return arr.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
    }, {});
};
