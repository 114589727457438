import * as pathing from "../path.js";
/**
 * Path for `AppInfo`.
 */
export function appDataPath(app) {
    return pathing.directory(pathing.Branch.Private, "Apps", app.creator, app.name);
}
/**
 * Lists the filesystems paths for a set of `Permissions`.
 * This'll return a list of `DistinctivePath`s.
 */
export function paths(permissions) {
    var _a, _b, _c, _d;
    let list = [];
    if (permissions.app)
        list.push(appDataPath(permissions.app));
    if ((_a = permissions.fs) === null || _a === void 0 ? void 0 : _a.private)
        list = list.concat((_b = permissions.fs) === null || _b === void 0 ? void 0 : _b.private.map(p => pathing.combine(pathing.directory(pathing.Branch.Private), p)));
    if ((_c = permissions.fs) === null || _c === void 0 ? void 0 : _c.public)
        list = list.concat((_d = permissions.fs) === null || _d === void 0 ? void 0 : _d.public.map(p => pathing.combine(pathing.directory(pathing.Branch.Public), p)));
    return list;
}
