import * as storage from "../storage/index.js";
import { setup } from "../setup/internal.js";
const FS_CID_LOG_PREFIX = "webnative.wnfs_cid_log";
function key() {
    return FS_CID_LOG_PREFIX + "-" + setup.endpoints.lobby;
}
// QUERYING
export async function get() {
    return (await storage.getItem(key())) || [];
}
export async function index(cid) {
    const log = await get();
    return [log.indexOf(cid), log.length];
}
export async function newest() {
    return (await get())[0];
}
// MUTATION
export async function add(cid) {
    const log = await get();
    const newLog = [cid, ...log].slice(0, 1000);
    await storage.setItem(key(), newLog);
}
export async function clear() {
    await storage.removeItem(key());
}
