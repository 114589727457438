import BaseTree from "../base/tree.js";
import PrivateFile from "./PrivateFile.js";
import PrivateHistory from "./PrivateHistory.js";
import { isObject, mapObj, removeKeyFromObj } from "../../common/index.js";
import * as crypto from "../../crypto/index.js";
import * as check from "../protocol/private/types/check.js";
import * as metadata from "../metadata.js";
import * as namefilter from "../protocol/private/namefilter.js";
import * as protocol from "../protocol/index.js";
import * as versions from "../versions.js";
export default class PrivateTree extends BaseTree {
    constructor({ mmpt, key, header }) {
        super();
        this.children = {};
        this.header = header;
        this.history = new PrivateHistory(this);
        this.key = key;
        this.mmpt = mmpt;
    }
    static instanceOf(obj) {
        return isObject(obj)
            && obj.mmpt !== undefined
            && check.isPrivateTreeInfo(obj.header);
    }
    static async create(mmpt, key, parentNameFilter) {
        const bareNameFilter = parentNameFilter
            ? await namefilter.addToBare(parentNameFilter, key)
            : await namefilter.createBare(key);
        return new PrivateTree({
            mmpt,
            key,
            header: {
                metadata: metadata.empty(false, versions.latest),
                bareNameFilter,
                revision: 1,
                links: {},
                skeleton: {},
            }
        });
    }
    static async fromBaseKey(mmpt, key) {
        const bareNameFilter = await namefilter.createBare(key);
        return this.fromBareNameFilter(mmpt, bareNameFilter, key);
    }
    static async fromBareNameFilter(mmpt, bareNameFilter, key) {
        const info = await protocol.priv.getLatestByBareNameFilter(mmpt, bareNameFilter, key);
        return this.fromInfo(mmpt, key, info);
    }
    static async fromLatestName(mmpt, name, key) {
        const info = await protocol.priv.getByLatestName(mmpt, name, key);
        return this.fromInfo(mmpt, key, info);
    }
    static async fromName(mmpt, name, key) {
        const info = await protocol.priv.getByName(mmpt, name, key);
        return this.fromInfo(mmpt, key, info);
    }
    static async fromInfo(mmpt, key, info) {
        if (!check.isPrivateTreeInfo(info)) {
            throw new Error(`Could not parse a valid private tree using the given key`);
        }
        return new PrivateTree({ mmpt, key, header: info });
    }
    async createChildTree(name, onUpdate) {
        const key = await crypto.aes.genKeyStr();
        const child = await PrivateTree.create(this.mmpt, key, this.header.bareNameFilter);
        const existing = this.children[name];
        if (existing) {
            if (PrivateFile.instanceOf(existing)) {
                throw new Error(`There is a file at the given path: ${name}`);
            }
            return existing;
        }
        await this.updateDirectChild(child, name, onUpdate);
        return child;
    }
    async createOrUpdateChildFile(content, name, onUpdate) {
        const existing = await this.getDirectChild(name);
        let file;
        if (existing === null) {
            const key = await crypto.aes.genKeyStr();
            file = await PrivateFile.create(this.mmpt, content, this.header.bareNameFilter, key);
        }
        else if (PrivateFile.instanceOf(existing)) {
            file = await existing.updateContent(content);
        }
        else {
            throw new Error(`There is already a directory with that name: ${name}`);
        }
        await this.updateDirectChild(file, name, onUpdate);
        return file;
    }
    async putDetailed() {
        // copy the object, so we're putting the current version & don't include any revisions
        const nodeCopy = Object.assign({}, this.header);
        return protocol.priv.addNode(this.mmpt, nodeCopy, this.key);
    }
    async updateDirectChild(child, name, onUpdate) {
        await child.updateParentNameFilter(this.header.bareNameFilter);
        this.children[name] = child;
        const details = await child.putDetailed();
        this.updateLink(name, details);
        onUpdate && await onUpdate();
        return this;
    }
    removeDirectChild(name) {
        this.header = {
            ...this.header,
            revision: this.header.revision + 1,
            links: removeKeyFromObj(this.header.links, name),
            skeleton: removeKeyFromObj(this.header.skeleton, name)
        };
        if (this.children[name]) {
            delete this.children[name];
        }
        return this;
    }
    async getDirectChild(name) {
        if (this.children[name]) {
            return this.children[name];
        }
        const childInfo = this.header.links[name];
        if (childInfo === undefined)
            return null;
        const child = childInfo.isFile
            ? await PrivateFile.fromLatestName(this.mmpt, childInfo.pointer, childInfo.key)
            : await PrivateTree.fromLatestName(this.mmpt, childInfo.pointer, childInfo.key);
        // check that the child wasn't added while retrieving the content from the network
        if (this.children[name]) {
            return this.children[name];
        }
        this.children[name] = child;
        return child;
    }
    async getName() {
        const { bareNameFilter, revision } = this.header;
        const revisionFilter = await namefilter.addRevision(bareNameFilter, this.key, revision);
        return namefilter.toPrivateName(revisionFilter);
    }
    async updateParentNameFilter(parentNameFilter) {
        this.header.bareNameFilter = await namefilter.addToBare(parentNameFilter, this.key);
        return this;
    }
    async get(path) {
        if (path.length === 0)
            return this;
        const [head, ...rest] = path;
        const next = this.header.skeleton[head];
        if (next === undefined)
            return null;
        const result = await this.getRecurse(next, rest);
        if (result === null)
            return null;
        return check.isPrivateFileInfo(result.node)
            ? PrivateFile.fromInfo(this.mmpt, result.key, result.node)
            : PrivateTree.fromInfo(this.mmpt, result.key, result.node);
    }
    async getRecurse(nodeInfo, parts) {
        const [head, ...rest] = parts;
        if (head === undefined)
            return {
                key: nodeInfo.key,
                node: await protocol.priv.getLatestByCID(this.mmpt, nodeInfo.cid, nodeInfo.key)
            };
        const nextChild = nodeInfo.subSkeleton[head];
        if (nextChild !== undefined) {
            return this.getRecurse(nextChild, rest);
        }
        const reloadedNode = await protocol.priv.getLatestByCID(this.mmpt, nodeInfo.cid, nodeInfo.key);
        if (!check.isPrivateTreeInfo(reloadedNode)) {
            return null;
        }
        const reloadedNext = reloadedNode.skeleton[head];
        return reloadedNext === undefined ? null : this.getRecurse(reloadedNext, rest);
    }
    getLinks() {
        return mapObj(this.header.links, (link) => {
            const { key, ...rest } = link;
            return { ...rest };
        });
    }
    updateLink(name, result) {
        const now = Date.now();
        const { cid, size, key, isFile, skeleton } = result;
        const pointer = result.name;
        this.header.links[name] = { name, key, pointer, size, isFile: isFile, mtime: metadata.mtimeFromMs(now) };
        this.header.skeleton[name] = { cid, key, subSkeleton: skeleton };
        this.header.revision = this.header.revision + 1;
        this.header.metadata.unixMeta.mtime = now;
        return this;
    }
}
