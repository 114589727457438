// FUNCTIONS
export const encode = (major, minor, patch) => {
    return {
        major,
        minor,
        patch
    };
};
export const fromString = (str) => {
    const parts = str.split(".").map(x => parseInt(x)); // dont shorten this because parseInt has a second param
    if (parts.length !== 3 || parts.some(p => typeof p !== "number")) {
        return null;
    }
    return {
        major: parts[0],
        minor: parts[1],
        patch: parts[2]
    };
};
export const toString = (version) => {
    const { major, minor, patch } = version;
    return `${major}.${minor}.${patch}`;
};
export const isSmallerThan = (a, b) => {
    return a.major < b.major || a.minor < b.major || a.patch < b.patch;
};
// VERSIONS
export const v0 = encode(0, 0, 0);
export const v1 = encode(1, 0, 0);
export const latest = encode(1, 0, 0);
