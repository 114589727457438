import * as protocol from "../protocol/index.js";
export default class PrivateHistory {
    constructor(node) {
        this.node = node;
    }
    /**
     * Go back one or more versions.
     *
     * @param delta Optional negative number to specify how far to go back
     */
    async back(delta = -1) {
        var _a;
        const n = Math.min(delta, -1);
        const revision = (_a = this.node.header) === null || _a === void 0 ? void 0 : _a.revision;
        return (revision && await this._getRevision(revision + n)) || null;
    }
    // async forward(delta: number = 1): Promise<Maybe<Node>> {
    //   const n = Math.max(delta, 1)
    //   const revision = this.node.header?.revision
    //   return (revision && await this._getRevision(revision + n)) || null
    // }
    /**
     * Get a version before a given timestamp.
     *
     * @param timestamp Unix timestamp in seconds
     */
    async prior(timestamp) {
        if (this.node.header.metadata.unixMeta.mtime < timestamp) {
            return this.node;
        }
        else {
            return this._prior(this.node.header.revision - 1, timestamp);
        }
    }
    /**
     * List earlier versions along with the timestamp they were created.
     */
    async list(amount = 5) {
        const max = this.node.header.revision;
        return Promise.all(Array.from({ length: amount }, (_, i) => {
            const n = i + 1;
            return this._getRevisionInfoFromNumber(max - n).then(info => ({
                revisionInfo: info,
                delta: -n
            }));
        })).then(list => list.filter(a => !!a.revisionInfo)).then(list => list.map(a => {
            const mtime = a.revisionInfo.metadata.unixMeta.mtime;
            return { delta: a.delta, timestamp: mtime };
        }));
    }
    /**
     * @internal
     */
    async _getRevision(revision) {
        const info = await this._getRevisionInfoFromNumber(revision);
        return info && await this.node.constructor.fromInfo(this.node.mmpt, this.node.key, info);
    }
    /**
     * @internal
     */
    _getRevisionInfo(revision) {
        return protocol.priv.readNode(revision.cid, this.node.key);
    }
    /**
     * @internal
     */
    async _getRevisionInfoFromNumber(revision) {
        const { key, mmpt } = this.node;
        const { bareNameFilter } = this.node.header;
        const r = await protocol.priv.getRevision(mmpt, bareNameFilter, key, revision);
        return r && this._getRevisionInfo(r);
    }
    /**
     * @internal
     */
    async _prior(revision, timestamp) {
        const info = await this._getRevisionInfoFromNumber(revision);
        if (!(info === null || info === void 0 ? void 0 : info.revision))
            return null;
        if (info.metadata.unixMeta.mtime < timestamp) {
            return this._getRevision(info.revision);
        }
        else {
            return this._prior(info.revision - 1, timestamp);
        }
    }
}
