import { webcrypto } from 'one-webcrypto';
import utils from '../utils.js';
import { DEFAULT_SYMM_ALG, DEFAULT_SYMM_LEN } from '../constants.js';
export async function makeKey(opts) {
    return webcrypto.subtle.generateKey({
        name: opts?.alg || DEFAULT_SYMM_ALG,
        length: opts?.length || DEFAULT_SYMM_LEN,
    }, true, ['encrypt', 'decrypt']);
}
export async function importKey(base64key, opts) {
    const buf = utils.base64ToArrBuf(base64key);
    return webcrypto.subtle.importKey('raw', buf, {
        name: opts?.alg || DEFAULT_SYMM_ALG,
        length: opts?.length || DEFAULT_SYMM_LEN,
    }, true, ['encrypt', 'decrypt']);
}
export default {
    makeKey,
    importKey,
};
