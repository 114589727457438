import { throttle } from "throttle-debounce";
import { Branch } from "../path.js";
import RootTree from "./root/tree.js";
import PublicTree from "./v1/PublicTree.js";
import PrivateFile from "./v1/PrivateFile.js";
import PrivateTree from "./v1/PrivateTree.js";
import * as cidLog from "../common/cid-log.js";
import * as dataRoot from "../data-root.js";
import * as debug from "../common/debug.js";
import * as crypto from "../crypto/index.js";
import * as did from "../did/index.js";
import * as pathing from "../path.js";
import * as typeCheck from "./types/check.js";
import * as ucan from "../ucan/index.js";
import { NoPermissionError } from "../errors.js";
import { appDataPath } from "../ucan/permissions.js";
// CONSTANTS
export const EXCHANGE_PATH = pathing.directory(pathing.Branch.Public, ".well-known", "exchange");
// CLASS
export class FileSystem {
    constructor({ root, permissions, localOnly }) {
        this.localOnly = localOnly || false;
        this.proofs = {};
        this.publishHooks = [];
        this.root = root;
        this._publishWhenOnline = [];
        this._publishing = false;
        this._whenOnline = this._whenOnline.bind(this);
        this._beforeLeaving = this._beforeLeaving.bind(this);
        const globe = globalThis;
        globe.filesystems = globe.filesystems || [];
        globe.filesystems.push(this);
        if (permissions &&
            permissions.app &&
            permissions.app.creator &&
            permissions.app.name) {
            this.appPath = appPath(permissions);
        }
        // Add the root CID of the file system to the CID log
        // (reverse list, newest cid first)
        const logCid = async (cid) => {
            await cidLog.add(cid);
            debug.log("📓 Adding to the CID ledger:", cid);
        };
        // Update the user's data root when making changes
        const updateDataRootWhenOnline = throttle(3000, false, (cid, proof) => {
            if (globalThis.navigator.onLine) {
                this._publishing = [cid, true];
                return dataRoot.update(cid, proof).then(() => {
                    if (this._publishing && this._publishing[0] === cid) {
                        this._publishing = false;
                    }
                });
            }
            this._publishWhenOnline.push([cid, proof]);
        }, false);
        this.publishHooks.push(logCid);
        this.publishHooks.push(updateDataRootWhenOnline);
        if (!this.localOnly) {
            // Publish when coming back online
            globalThis.addEventListener("online", this._whenOnline);
            // Show an alert when leaving the page while updating the data root
            globalThis.addEventListener("beforeunload", this._beforeLeaving);
        }
    }
    // INITIALISATION
    // --------------
    /**
     * Creates a file system with an empty public tree & an empty private tree at the root.
     */
    static async empty(opts = {}) {
        const { permissions, localOnly } = opts;
        const rootKey = opts.rootKey || await crypto.aes.genKeyStr();
        const root = await RootTree.empty({ rootKey });
        const fs = new FileSystem({
            root,
            permissions,
            localOnly
        });
        return fs;
    }
    /**
     * Loads an existing file system from a CID.
     */
    static async fromCID(cid, opts = {}) {
        const { permissions, localOnly } = opts;
        const root = await RootTree.fromCID({ cid, permissions });
        const fs = new FileSystem({
            root,
            permissions,
            localOnly
        });
        return fs;
    }
    // DEACTIVATE
    // ----------
    /**
     * Deactivate a file system.
     *
     * Use this when a user signs out.
     * The only function of this is to stop listing to online/offline events.
     */
    deactivate() {
        if (this.localOnly)
            return;
        const globe = globalThis;
        globe.filesystems = globe.filesystems.filter((a) => a !== this);
        globe.removeEventListener("online", this._whenOnline);
        globe.removeEventListener("beforeunload", this._beforeLeaving);
    }
    // POSIX INTERFACE (DIRECTORIES)
    // -----------------------------
    async ls(path) {
        if (pathing.isFile(path))
            throw new Error("`ls` only accepts directory paths");
        return this.runOnNode(path, false, (node, relPath) => {
            if (typeCheck.isFile(node)) {
                throw new Error("Tried to `ls` a file");
            }
            else {
                return node.ls(relPath);
            }
        });
    }
    async mkdir(path, options = {}) {
        if (pathing.isFile(path))
            throw new Error("`mkdir` only accepts directory paths");
        await this.runOnNode(path, true, (node, relPath) => {
            if (typeCheck.isFile(node)) {
                throw new Error("Tried to `mkdir` a file");
            }
            else {
                return node.mkdir(relPath);
            }
        });
        if (options.publish) {
            await this.publish();
        }
        return this;
    }
    // POSIX INTERFACE (FILES)
    // -----------------------
    async add(path, content, options = {}) {
        if (pathing.isDirectory(path))
            throw new Error("`add` only accepts file paths");
        await this.runOnNode(path, true, async (node, relPath) => {
            return typeCheck.isFile(node)
                ? node.updateContent(content)
                : node.add(relPath, content);
        });
        if (options.publish) {
            await this.publish();
        }
        return this;
    }
    async cat(path) {
        if (pathing.isDirectory(path))
            throw new Error("`cat` only accepts file paths");
        return this.runOnNode(path, false, async (node, relPath) => {
            return typeCheck.isFile(node)
                ? node.content
                : node.cat(relPath);
        });
    }
    async read(path) {
        if (pathing.isDirectory(path))
            throw new Error("`read` only accepts file paths");
        return this.cat(path);
    }
    async write(path, content, options = {}) {
        if (pathing.isDirectory(path))
            throw new Error("`write` only accepts file paths");
        return this.add(path, content, options);
    }
    // POSIX INTERFACE (GENERAL)
    // -------------------------
    async exists(path) {
        return this.runOnNode(path, false, async (node, relPath) => {
            return typeCheck.isFile(node)
                ? true // tried to check the existance of itself
                : node.exists(relPath);
        });
    }
    async get(path) {
        return this.runOnNode(path, false, async (node, relPath) => {
            return typeCheck.isFile(node)
                ? node // tried to get itself
                : node.get(relPath);
        });
    }
    // This is only implemented on the same tree for now and will error otherwise
    async mv(from, to) {
        const sameTree = pathing.isSameBranch(from, to);
        if (!pathing.isSameKind(from, to)) {
            const kindFrom = pathing.kind(from);
            const kindTo = pathing.kind(to);
            throw new Error(`Can't move to a different kind of path, from is a ${kindFrom} and to is a ${kindTo}`);
        }
        if (!sameTree) {
            throw new Error("`mv` is only supported on the same tree for now");
        }
        if (await this.exists(to)) {
            throw new Error("Destination already exists");
        }
        await this.runOnNode(from, true, (node, relPath) => {
            if (typeCheck.isFile(node)) {
                throw new Error("Tried to `mv` within a file");
            }
            const [head, ...nextPath] = pathing.unwrap(to);
            return node.mv(relPath, nextPath);
        });
        return this;
    }
    async rm(path) {
        await this.runOnNode(path, true, (node, relPath) => {
            if (typeCheck.isFile(node)) {
                throw new Error("Cannot `rm` a file you've asked permission for");
            }
            else {
                return node.rm(relPath);
            }
        });
        return this;
    }
    // PUBLISH
    // -------
    /**
     * Ensures the latest version of the file system is added to IPFS,
     * updates your data root, and returns the root CID.
     */
    async publish() {
        const proofs = Array.from(Object.entries(this.proofs));
        this.proofs = {};
        const cid = await this.root.put();
        proofs.forEach(([_, proof]) => {
            this.publishHooks.forEach(hook => hook(cid, proof));
        });
        return cid;
    }
    // COMMON
    // ------
    /**
     * Stores the public part of the exchange key in the DID format,
     * in the `/public/.well-known/exchange/DID_GOES_HERE/` directory.
     */
    async addPublicExchangeKey() {
        const publicDid = await did.exchange();
        await this.mkdir(pathing.combine(EXCHANGE_PATH, pathing.directory(publicDid)));
    }
    /**
     * Checks if the public exchange key was added in the well-known location.
     * See `addPublicExchangeKey()` for the exact details.
     */
    async hasPublicExchangeKey() {
        const publicDid = await did.exchange();
        return this.exists(pathing.combine(EXCHANGE_PATH, pathing.directory(publicDid)));
    }
    // INTERNAL
    // --------
    /** @internal */
    async runOnNode(path, isMutation, fn) {
        const parts = pathing.unwrap(path);
        const head = parts[0];
        const relPath = parts.slice(1);
        const operation = isMutation
            ? "make changes to"
            : "query";
        if (!this.localOnly) {
            const proof = await ucan.dictionary.lookupFilesystemUcan(path);
            const decodedProof = proof && ucan.decode(proof);
            if (!proof || !decodedProof || ucan.isExpired(decodedProof) || !decodedProof.signature) {
                throw new NoPermissionError(`I don't have the necessary permissions to ${operation} the file system at "${pathing.toPosix(path)}"`);
            }
            this.proofs[decodedProof.signature] = proof;
        }
        let result;
        let resultPretty;
        if (head === Branch.Public) {
            result = await fn(this.root.publicTree, relPath);
            if (isMutation && PublicTree.instanceOf(result)) {
                resultPretty = await fn(this.root.prettyTree, relPath);
                this.root.publicTree = result;
                this.root.prettyTree = resultPretty;
                await Promise.all([
                    this.root.updatePuttable(Branch.Public, this.root.publicTree),
                    this.root.updatePuttable(Branch.Pretty, this.root.prettyTree)
                ]);
            }
        }
        else if (head === Branch.Private) {
            const [nodePath, node] = this.root.findPrivateNode(path);
            if (!node) {
                throw new NoPermissionError(`I don't have the necessary permissions to ${operation} the file system at "${pathing.toPosix(path)}"`);
            }
            result = await fn(node, parts.slice(pathing.unwrap(nodePath).length));
            if (isMutation &&
                (PrivateTree.instanceOf(result) || PrivateFile.instanceOf(result))) {
                this.root.privateNodes[pathing.toPosix(nodePath)] = result;
                await result.put();
                await this.root.updatePuttable(Branch.Private, this.root.mmpt);
                const cid = await this.root.mmpt.put();
                await this.root.addPrivateLogEntry(cid);
            }
        }
        else if (head === Branch.Pretty && isMutation) {
            throw new Error("The pretty path is read only");
        }
        else if (head === Branch.Pretty) {
            result = await fn(this.root.prettyTree, relPath);
        }
        else {
            throw new Error("Not a valid FileSystem path");
        }
        return result;
    }
    /** @internal */
    _whenOnline() {
        const toPublish = [...this._publishWhenOnline];
        this._publishWhenOnline = [];
        toPublish.forEach(([cid, proof]) => {
            this.publishHooks.forEach(hook => hook(cid, proof));
        });
    }
    /** @internal */
    _beforeLeaving(e) {
        const msg = "Are you sure you want to leave? We don't control the browser so you may lose your data.";
        if (this._publishing || this._publishWhenOnline.length) {
            (e || globalThis.event).returnValue = msg;
            return msg;
        }
    }
}
export default FileSystem;
// ㊙️
function appPath(permissions) {
    if (!permissions.app)
        throw Error("Only works with app permissions");
    const base = appDataPath(permissions.app);
    return ((path) => {
        if (path)
            return pathing.combine(base, path);
        return base;
    });
}
