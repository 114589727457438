import * as ipfs from "../../ipfs/index.js";
import * as link from "../link.js";
export const getFile = async (cid) => {
    return ipfs.catBuf(cid);
};
export const getEncryptedFile = async (cid, key) => {
    return ipfs.encoded.catAndDecode(cid, key);
};
export const putFile = async (content) => {
    return ipfs.add(content);
};
export const putEncryptedFile = async (content, key) => {
    return ipfs.encoded.add(content, key);
};
export const getSimpleLinks = async (cid) => {
    const dagNode = await ipfs.dagGet(cid);
    return link.arrToMap(dagNode.Links.map(link.fromDAGLink));
};
export const getLinks = async (cid) => {
    const raw = await ipfs.ls(cid);
    const links = link.arrToMap(raw.map(link.fromFSFile));
    // ipfs.ls does not return size, so we need to interpolate that in ourselves
    // @@TODO: split into two functions: getLinks & getLinksDetailed. mtime & isFile are stored in our FS format in all but the pretty tree
    const dagNode = await ipfs.dagGet(cid);
    dagNode.Links.forEach((l) => {
        if (links[l.Name] && links[l.Name].size === 0) {
            links[l.Name].size = l.Tsize;
        }
    });
    return links;
};
export const putLinks = async (links) => {
    const dagLinks = Object.values(links)
        .filter(l => l !== undefined)
        .map(link.toDAGLink);
    return ipfs.dagPutLinks(dagLinks);
};
