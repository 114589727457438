import * as storage from "../storage/index.js";
import { UCANS_STORAGE_KEY } from "../common/index.js";
let dictionary = {};
/**
 * Retrieve dictionary
 */
export function getDictionary() {
    return dictionary;
}
/**
 * Retrieve dictionary
 */
export function setDictionary(updated) {
    dictionary = updated;
}
/**
 * You didn't see anything 👀
 */
export async function clearStorage() {
    dictionary = {};
    await storage.removeItem(UCANS_STORAGE_KEY);
}
