import tweetnacl from "tweetnacl";
import rsaOperations from "keystore-idb/lib/rsa/index.js";
import utils from "keystore-idb/lib/utils.js";
import { assertBrowser } from "../common/browser.js";
import * as keystore from "../keystore.js";
export const rsaVerify = (message, signature, publicKey) => {
    assertBrowser("rsa.verify");
    const keyStr = utils.arrBufToBase64(publicKey.buffer);
    return rsaOperations.verify(message, signature, keyStr);
};
export const ed25519Verify = async (message, signature, publicKey) => {
    return tweetnacl.sign.detached.verify(message, signature, publicKey);
};
export const ksPublicExchangeKey = async () => {
    assertBrowser("keystore.publicExchangeKey");
    const ks = await keystore.get();
    return ks.publicExchangeKey();
};
export const ksPublicWriteKey = async () => {
    assertBrowser("keystore.publicWriteKey");
    const ks = await keystore.get();
    return ks.publicWriteKey();
};
export const ksDecrypt = async (encrypted) => {
    assertBrowser("keystore.decrypt");
    const ks = await keystore.get();
    return ks.decrypt(encrypted);
};
export const ksSign = async (message, charSize) => {
    assertBrowser("keystore.sign");
    const ks = await keystore.get();
    return ks.sign(message, { charSize });
};
export const ksImportSymmKey = async (key, name) => {
    assertBrowser("keystore.importSymmKey");
    const ks = await keystore.get();
    return ks.importSymmKey(key, name);
};
export const ksExportSymmKey = async (name) => {
    assertBrowser("keystore.exportSymmKey");
    const ks = await keystore.get();
    return ks.exportSymmKey(name);
};
export const ksKeyExists = async (name) => {
    assertBrowser("keystore.keyExists");
    const ks = await keystore.get();
    return ks.keyExists(name);
};
export const ksGetAlg = async () => {
    assertBrowser("keystore.getAlg");
    const ks = await keystore.get();
    return ks.cfg.type;
};
export const ksClear = async () => {
    assertBrowser("keystore.clear");
    return keystore.clear();
};
