import { webcrypto } from 'one-webcrypto';
import utils from '../utils.js';
import { ECC_EXCHANGE_ALG, ECC_WRITE_ALG } from '../constants.js';
import { KeyUse } from '../types.js';
import { checkValidKeyUse } from '../errors.js';
export async function makeKeypair(curve, use) {
    checkValidKeyUse(use);
    const alg = use === KeyUse.Exchange ? ECC_EXCHANGE_ALG : ECC_WRITE_ALG;
    const uses = use === KeyUse.Exchange ? ['deriveKey', 'deriveBits'] : ['sign', 'verify'];
    return webcrypto.subtle.generateKey({ name: alg, namedCurve: curve }, false, uses);
}
export async function importPublicKey(base64Key, curve, use) {
    checkValidKeyUse(use);
    const alg = use === KeyUse.Exchange ? ECC_EXCHANGE_ALG : ECC_WRITE_ALG;
    const uses = use === KeyUse.Exchange ? [] : ['verify'];
    const buf = utils.base64ToArrBuf(base64Key);
    return webcrypto.subtle.importKey('raw', buf, { name: alg, namedCurve: curve }, true, uses);
}
export default {
    makeKeypair,
    importPublicKey
};
