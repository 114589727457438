import IDB from '../idb.js';
import keys from './keys.js';
import operations from './operations.js';
import config from '../config.js';
import utils from '../utils.js';
import KeyStoreBase from '../keystore/base.js';
import { KeyUse, CryptoSystem } from '../types.js';
export class ECCKeyStore extends KeyStoreBase {
    static async init(maybeCfg) {
        const cfg = config.normalize({
            ...(maybeCfg || {}),
            type: CryptoSystem.ECC
        });
        const { curve, storeName, exchangeKeyName, writeKeyName } = cfg;
        const store = IDB.createStore(storeName);
        await IDB.createIfDoesNotExist(exchangeKeyName, () => (keys.makeKeypair(curve, KeyUse.Exchange)), store);
        await IDB.createIfDoesNotExist(writeKeyName, () => (keys.makeKeypair(curve, KeyUse.Write)), store);
        return new ECCKeyStore(cfg, store);
    }
    async sign(msg, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const writeKey = await this.writeKey();
        return utils.arrBufToBase64(await operations.sign(msg, writeKey.privateKey, mergedCfg.charSize, mergedCfg.hashAlg));
    }
    async verify(msg, sig, publicKey, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        return operations.verify(msg, sig, publicKey, mergedCfg.charSize, mergedCfg.curve, mergedCfg.hashAlg);
    }
    async encrypt(msg, publicKey, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const exchangeKey = await this.exchangeKey();
        return utils.arrBufToBase64(await operations.encrypt(msg, exchangeKey.privateKey, publicKey, mergedCfg.charSize, mergedCfg.curve));
    }
    async decrypt(cipherText, publicKey, cfg) {
        const mergedCfg = config.merge(this.cfg, cfg);
        const exchangeKey = await this.exchangeKey();
        return utils.arrBufToStr(await operations.decrypt(cipherText, exchangeKey.privateKey, publicKey, mergedCfg.curve), mergedCfg.charSize);
    }
    async publicExchangeKey() {
        const exchangeKey = await this.exchangeKey();
        return operations.getPublicKey(exchangeKey);
    }
    async publicWriteKey() {
        const writeKey = await this.writeKey();
        return operations.getPublicKey(writeKey);
    }
}
export default ECCKeyStore;
