export const isDefined = (val) => {
    return val !== undefined;
};
export const notNull = (val) => {
    return val !== null;
};
export const isJust = notNull;
export const isValue = (val) => {
    return isDefined(val) && notNull(val);
};
export const isBool = (val) => {
    return typeof val === "boolean";
};
export const isNum = (val) => {
    return typeof val === "number";
};
export const isString = (val) => {
    return typeof val === "string";
};
export const isObject = (val) => {
    return val !== null && typeof val === "object";
};
export const isBlob = (val) => {
    var _a;
    if (typeof Blob === "undefined")
        return false;
    return val instanceof Blob || (isObject(val) && ((_a = val === null || val === void 0 ? void 0 : val.constructor) === null || _a === void 0 ? void 0 : _a.name) === "Blob");
};
