import * as browserCrypto from "../crypto/browser.js";
import * as browserStorage from "../storage/browser.js";
export const DEFAULT_IMPLEMENTATION = {
    rsa: {
        verify: browserCrypto.rsaVerify
    },
    ed25519: {
        verify: browserCrypto.ed25519Verify
    },
    keystore: {
        publicExchangeKey: browserCrypto.ksPublicExchangeKey,
        publicWriteKey: browserCrypto.ksPublicWriteKey,
        decrypt: browserCrypto.ksDecrypt,
        sign: browserCrypto.ksSign,
        importSymmKey: browserCrypto.ksImportSymmKey,
        exportSymmKey: browserCrypto.ksExportSymmKey,
        keyExists: browserCrypto.ksKeyExists,
        getAlg: browserCrypto.ksGetAlg,
        clear: browserCrypto.ksClear,
    },
    storage: {
        getItem: browserStorage.getItem,
        setItem: browserStorage.setItem,
        removeItem: browserStorage.removeItem,
        clear: browserStorage.clear,
    }
};
export let impl = DEFAULT_IMPLEMENTATION;
export const setDependencies = (fns) => {
    impl = {
        rsa: merge(impl.rsa, fns.rsa),
        ed25519: merge(impl.ed25519, fns.ed25519),
        keystore: merge(impl.keystore, fns.keystore),
        storage: merge(impl.storage, fns.storage),
    };
    return impl;
};
const merge = (first, second) => {
    return {
        ...first,
        ...(second || {})
    };
};
