import BaseTree from "../base/tree.js";
import PublicFile from "./PublicFile.js";
import PublicHistory from "./PublicHistory.js";
import * as check from "../types/check.js";
import * as link from "../link.js";
import * as metadata from "../metadata.js";
import * as protocol from "../protocol/index.js";
import * as skeleton from "../protocol/public/skeleton.js";
import * as versions from "../versions.js";
export class PublicTree extends BaseTree {
    constructor({ links, header, cid }) {
        super();
        this.children = {};
        this.cid = cid;
        this.links = links;
        this.header = header;
        this.history = new PublicHistory(this);
    }
    static async empty() {
        return new PublicTree({
            links: {},
            header: {
                metadata: metadata.empty(false, versions.latest),
                skeleton: {},
            },
            cid: null
        });
    }
    static async fromCID(cid) {
        const info = await protocol.pub.get(cid);
        if (!check.isTreeInfo(info)) {
            throw new Error(`Could not parse a valid public tree at: ${cid}`);
        }
        return PublicTree.fromInfo(info, cid);
    }
    static async fromInfo(info, cid) {
        const { userland, metadata, previous, skeleton } = info;
        const links = await protocol.basic.getLinks(userland);
        return new PublicTree({
            links,
            header: { metadata, previous, skeleton },
            cid
        });
    }
    static instanceOf(obj) {
        return check.isLinks(obj.links) && check.isTreeHeader(obj.header);
    }
    async createChildTree(name, onUpdate) {
        const child = await PublicTree.empty();
        const existing = this.children[name];
        if (existing) {
            if (PublicFile.instanceOf(existing)) {
                throw new Error(`There is a file at the given path: ${name}`);
            }
            else if (!PublicTree.instanceOf(existing)) {
                throw new Error(`Not a public tree at the given path: ${name}`);
            }
            else {
                return existing;
            }
        }
        await this.updateDirectChild(child, name, onUpdate);
        return child;
    }
    async createOrUpdateChildFile(content, name, onUpdate) {
        const existing = await this.getDirectChild(name);
        let file;
        if (existing === null) {
            file = await PublicFile.create(content);
        }
        else if (PublicFile.instanceOf(existing)) {
            file = await existing.updateContent(content);
        }
        else {
            throw new Error(`There is already a directory with that name: ${name}`);
        }
        await this.updateDirectChild(file, name, onUpdate);
        return file;
    }
    async putDetailed() {
        const details = await protocol.pub.putTree(this.links, this.header.skeleton, this.header.metadata, this.cid);
        this.header.previous = this.cid || undefined;
        this.cid = details.cid;
        return details;
    }
    async updateDirectChild(child, name, onUpdate) {
        this.children[name] = child;
        const details = await child.putDetailed();
        this.updateLink(name, details);
        onUpdate && await onUpdate();
        return this;
    }
    removeDirectChild(name) {
        delete this.links[name];
        delete this.header.skeleton[name];
        if (this.children[name]) {
            delete this.children[name];
        }
        return this;
    }
    async getDirectChild(name) {
        if (this.children[name]) {
            return this.children[name];
        }
        const childInfo = this.header.skeleton[name] || null;
        if (childInfo === null)
            return null;
        const child = childInfo.isFile
            ? await PublicFile.fromCID(childInfo.cid)
            : await PublicTree.fromCID(childInfo.cid);
        // check that the child wasn't added while retrieving the content from the network
        if (this.children[name]) {
            return this.children[name];
        }
        this.children[name] = child;
        return child;
    }
    async get(path) {
        if (path.length < 1)
            return this;
        const skeletonInfo = skeleton.getPath(this.header.skeleton, path);
        if (skeletonInfo === null)
            return null;
        const info = await protocol.pub.get(skeletonInfo.cid);
        return check.isFileInfo(info)
            ? PublicFile.fromInfo(info, skeletonInfo.cid)
            : PublicTree.fromInfo(info, skeletonInfo.cid);
    }
    getLinks() {
        // add missing metadata into links
        return Object.values(this.links).reduce((acc, cur) => {
            var _a;
            return {
                ...acc,
                [cur.name]: {
                    ...cur,
                    isFile: (_a = this.header.skeleton[cur.name]) === null || _a === void 0 ? void 0 : _a.isFile,
                }
            };
        }, {});
    }
    updateLink(name, result) {
        const { cid, metadata, userland, size, isFile, skeleton } = result;
        this.links[name] = link.make(name, cid, false, size);
        this.header.skeleton[name] = {
            cid,
            metadata,
            userland,
            subSkeleton: skeleton,
            isFile
        };
        this.header.metadata.unixMeta.mtime = Date.now();
        return this;
    }
}
export default PublicTree;
