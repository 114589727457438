export var CryptoSystem;
(function (CryptoSystem) {
    CryptoSystem["ECC"] = "ecc";
    CryptoSystem["RSA"] = "rsa";
})(CryptoSystem || (CryptoSystem = {}));
export var EccCurve;
(function (EccCurve) {
    EccCurve["P_256"] = "P-256";
    EccCurve["P_384"] = "P-384";
    EccCurve["P_521"] = "P-521";
})(EccCurve || (EccCurve = {}));
export var RsaSize;
(function (RsaSize) {
    RsaSize[RsaSize["B1024"] = 1024] = "B1024";
    RsaSize[RsaSize["B2048"] = 2048] = "B2048";
    RsaSize[RsaSize["B4096"] = 4096] = "B4096";
})(RsaSize || (RsaSize = {}));
export var SymmAlg;
(function (SymmAlg) {
    SymmAlg["AES_CTR"] = "AES-CTR";
    SymmAlg["AES_CBC"] = "AES-CBC";
    SymmAlg["AES_GCM"] = "AES-GCM";
})(SymmAlg || (SymmAlg = {}));
export var SymmKeyLength;
(function (SymmKeyLength) {
    SymmKeyLength[SymmKeyLength["B128"] = 128] = "B128";
    SymmKeyLength[SymmKeyLength["B192"] = 192] = "B192";
    SymmKeyLength[SymmKeyLength["B256"] = 256] = "B256";
})(SymmKeyLength || (SymmKeyLength = {}));
export var HashAlg;
(function (HashAlg) {
    HashAlg["SHA_1"] = "SHA-1";
    HashAlg["SHA_256"] = "SHA-256";
    HashAlg["SHA_384"] = "SHA-384";
    HashAlg["SHA_512"] = "SHA-512";
})(HashAlg || (HashAlg = {}));
export var CharSize;
(function (CharSize) {
    CharSize[CharSize["B8"] = 8] = "B8";
    CharSize[CharSize["B16"] = 16] = "B16";
})(CharSize || (CharSize = {}));
export var KeyUse;
(function (KeyUse) {
    KeyUse["Exchange"] = "exchange";
    KeyUse["Write"] = "write";
})(KeyUse || (KeyUse = {}));
