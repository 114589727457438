/** @internal */
import { isString, isObject, isNum, isBool } from "../../common/index.js";
export const isFile = (obj) => {
    return isObject(obj) && obj.content !== undefined;
};
export const isTree = (obj) => {
    return isObject(obj) && obj.ls !== undefined;
};
export const isBaseLink = (obj) => {
    return isObject(obj)
        && isString(obj.name)
        && isNum(obj.size)
        && isBool(obj.isFile);
};
export const isLink = (obj) => {
    return isBaseLink(obj)
        && isCID(obj.cid);
};
export const isLinks = (obj) => {
    return isObject(obj)
        && Object.values(obj).every(isLink);
};
export const isUnixMeta = (obj) => {
    return isObject(obj)
        && isNum(obj.mtime)
        && isNum(obj.ctime)
        && isNum(obj.mode)
        && isString(obj._type);
};
export const isMetadata = (obj) => {
    return isObject(obj)
        && isUnixMeta(obj.unixMeta)
        && isBool(obj.isFile)
        && isSemVer(obj.version);
};
export const isSkeleton = (obj) => {
    return isObject(obj)
        && Object.values(obj).every(val => (isObject(val)
            && isCID(val.cid)
            && isCID(val.userland)
            && isCID(val.metadata)
            && isSkeleton(val.subSkeleton)));
};
export const isTreeHeader = (obj) => {
    return isObject(obj)
        && isSkeleton(obj.skeleton)
        && isMetadata(obj.metadata)
        && obj.metadata.isFile === false;
};
export const isTreeInfo = (obj) => {
    return isTreeHeader(obj)
        && isCID(obj.userland);
};
export const isFileHeader = (obj) => {
    return isObject(obj)
        && isMetadata(obj.metadata)
        && obj.metadata.isFile === true;
};
export const isFileInfo = (obj) => {
    return isFileHeader(obj)
        && isCID(obj.userland);
};
export const isCID = (obj) => {
    return isString(obj);
};
export const isCIDList = (obj) => {
    return Array.isArray(obj)
        && obj.every(isCID);
};
export const isSemVer = (obj) => {
    if (!isObject(obj))
        return false;
    const { major, minor, patch } = obj;
    return isNum(major) && isNum(minor) && isNum(patch);
};
