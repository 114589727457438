import localforage from 'localforage';
import { checkIsKeyPair, checkIsKey } from './errors.js';
/* istanbul ignore next */
export function createStore(name) {
    return localforage.createInstance({ name });
}
export async function createIfDoesNotExist(id, makeFn, store = localforage) {
    if (await exists(id, store)) {
        return;
    }
    const key = await makeFn();
    await put(id, key, store);
}
/* istanbul ignore next */
export async function put(id, key, store = localforage) {
    return store.setItem(id, key);
}
/* istanbul ignore next */
export async function getKeypair(id, store = localforage) {
    return get(id, checkIsKeyPair, store);
}
/* istanbul ignore next */
export async function getKey(id, store = localforage) {
    return get(id, checkIsKey, store);
}
/* istanbul ignore next */
export async function get(id, checkFn, store = localforage) {
    const item = await store.getItem(id);
    return item === null ? null : checkFn(item);
}
/* istanbul ignore next */
export async function exists(id, store = localforage) {
    const key = await store.getItem(id);
    return key !== null;
}
/* istanbul ignore next */
export async function rm(id, store = localforage) {
    return store.removeItem(id);
}
export async function dropStore(store) {
    return store.dropInstance();
}
/* istanbul ignore next */
export async function clear(store) {
    if (store) {
        return dropStore(store);
    }
    else {
        return localforage.clear();
    }
}
export default {
    createStore,
    createIfDoesNotExist,
    put,
    getKeypair,
    getKey,
    exists,
    rm,
    dropStore,
    clear
};
