import dagPb from "ipld-dag-pb";
import OldCID from "cids";
const rawToDAGLink = (raw) => {
    const h = raw.Hash;
    const c = new OldCID(h.version, h.code, h.multihash.bytes);
    return new dagPb.DAGLink(raw.Name, raw.Tsize, c);
};
export const rawToDAGNode = (raw) => {
    var _a, _b, _c;
    const data = (_a = raw === null || raw === void 0 ? void 0 : raw.value) === null || _a === void 0 ? void 0 : _a.Data;
    const links = (_c = (_b = raw === null || raw === void 0 ? void 0 : raw.value) === null || _b === void 0 ? void 0 : _b.Links) === null || _c === void 0 ? void 0 : _c.map(rawToDAGLink);
    return new dagPb.DAGNode(data, links);
};
