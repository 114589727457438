import BaseFile from "../base/file.js";
import PublicHistory from "./PublicHistory.js";
import * as check from "../types/check.js";
import * as metadata from "../metadata.js";
import * as protocol from "../protocol/index.js";
import * as versions from "../versions.js";
import { isObject } from "../../common/index.js";
export class PublicFile extends BaseFile {
    constructor({ content, header, cid }) {
        super(content);
        this.cid = cid;
        this.header = header;
        this.history = new PublicHistory(this);
    }
    static instanceOf(obj) {
        return isObject(obj)
            && obj.content !== undefined
            && check.isFileHeader(obj.header);
    }
    static async create(content) {
        return new PublicFile({
            content,
            header: { metadata: metadata.empty(true, versions.latest) },
            cid: null
        });
    }
    static async fromCID(cid) {
        const info = await protocol.pub.get(cid);
        return PublicFile.fromInfo(info, cid);
    }
    static async fromInfo(info, cid) {
        const { userland, metadata, previous } = info;
        const content = await protocol.basic.getFile(userland);
        return new PublicFile({
            content,
            header: { metadata, previous },
            cid
        });
    }
    async putDetailed() {
        const details = await protocol.pub.putFile(this.content, metadata.updateMtime(this.header.metadata), this.cid);
        this.cid = details.cid;
        return details;
    }
}
export default PublicFile;
