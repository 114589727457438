import * as uint8arrays from "uint8arrays";
export const toUint8Array = async (blob) => {
    return new Promise((resolve, reject) => {
        const fail = () => reject(new Error("Failed to read file"));
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
            var _a;
            const arrbuf = ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.result) || null;
            if (arrbuf == null) {
                fail();
                return;
            }
            if (typeof arrbuf === "string") {
                resolve(uint8arrays.fromString(arrbuf));
                return;
            }
            resolve(new Uint8Array(arrbuf));
        });
        reader.addEventListener("error", () => reader.abort());
        reader.addEventListener("abort", fail);
        reader.readAsArrayBuffer(blob);
    });
};
