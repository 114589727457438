import * as cbor from "cborg";
import { isBlob, isJust } from "../common/type-checks.js";
import * as blob from "../common/blob.js";
import * as crypto from "../crypto/index.js";
import * as basic from "./basic.js";
export const add = async (content, key) => {
    // can't cbor encode blobs ie file streams
    const normalized = isBlob(content) ? await blob.toUint8Array(content) : content;
    const encoded = cbor.encode(normalized);
    const toAdd = isJust(key) ? await crypto.aes.encrypt(encoded, key) : encoded;
    return basic.add(toAdd);
};
export const catAndDecode = async (cid, key) => {
    const buf = await basic.catBuf(cid);
    const toDecode = isJust(key) ? await crypto.aes.decrypt(buf, key) : buf;
    return cbor.decode(toDecode);
};
