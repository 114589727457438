import * as protocol from "../protocol/index.js";
import BaseFile from "../base/file.js";
import { isObject } from "../../common/index.js";
export class BareFile extends BaseFile {
    static create(content) {
        return new BareFile(content);
    }
    static async fromCID(cid) {
        const content = await protocol.basic.getFile(cid);
        return new BareFile(content);
    }
    static instanceOf(obj) {
        return isObject(obj) && obj.content !== undefined;
    }
    async put() {
        const { cid } = await this.putDetailed();
        return cid;
    }
    async putDetailed() {
        return protocol.basic.putFile(await protocol.pub.normalizeFileContent(this.content));
    }
}
export default BareFile;
