import localforage from "localforage";
import { assertBrowser } from "../common/browser.js";
export const getItem = (key) => {
    assertBrowser("storage.getItem");
    return localforage.getItem(key);
};
export const setItem = (key, val) => {
    assertBrowser("storage.setItem");
    return localforage.setItem(key, val);
};
export const removeItem = (key) => {
    assertBrowser("storage.removeItem");
    return localforage.removeItem(key);
};
export const clear = () => {
    assertBrowser("storage.clear");
    return localforage.clear();
};
