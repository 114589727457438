import { webcrypto } from 'one-webcrypto';
import { RSA_EXCHANGE_ALG, RSA_WRITE_ALG } from '../constants.js';
import { KeyUse } from '../types.js';
import utils from '../utils.js';
import { checkValidKeyUse } from '../errors.js';
export async function makeKeypair(size, hashAlg, use) {
    checkValidKeyUse(use);
    const alg = use === KeyUse.Exchange ? RSA_EXCHANGE_ALG : RSA_WRITE_ALG;
    const uses = use === KeyUse.Exchange ? ['encrypt', 'decrypt'] : ['sign', 'verify'];
    return webcrypto.subtle.generateKey({
        name: alg,
        modulusLength: size,
        publicExponent: utils.publicExponent(),
        hash: { name: hashAlg }
    }, false, uses);
}
function stripKeyHeader(base64Key) {
    return base64Key
        .replace('-----BEGIN PUBLIC KEY-----\n', '')
        .replace('\n-----END PUBLIC KEY-----', '');
}
export async function importPublicKey(base64Key, hashAlg, use) {
    checkValidKeyUse(use);
    const alg = use === KeyUse.Exchange ? RSA_EXCHANGE_ALG : RSA_WRITE_ALG;
    const uses = use === KeyUse.Exchange ? ['encrypt'] : ['verify'];
    const buf = utils.base64ToArrBuf(stripKeyHeader(base64Key));
    return webcrypto.subtle.importKey('spki', buf, { name: alg, hash: { name: hashAlg } }, true, uses);
}
export default {
    makeKeypair,
    importPublicKey
};
